export function HotelLogo({ className, fillColor }: { className?: string; fillColor?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 217.49 65.32"
      className={className}
      fill={fillColor}
    >
      <g>
        <g>
          <path d="M31.18,22.44c.74,0,.99,.52,.99,1.05,0,2.05-1.88,4.14-5.45,5.45,.57-3.28,3-6.5,4.47-6.5M4.18,31.45c-.6,0-1.09-.34-1.09-.93,0-.65,.32-1.39,.64-2.25l3.48-8.87h6.29s-.83,2.14-1.18,3.02c-1.77,4.44-6.13,9.03-8.14,9.03ZM25.21,1.2c.6,0,.93,.4,.93,1.15,0,3.84-5.83,11.92-8.99,14.27,3.01-7.51,5.8-15.43,8.06-15.43ZM12.94,27.26l3.09-7.86h18.5l.39-.96H16.53c5.7-3.95,10.93-11.58,10.93-16.1,0-1.32-.66-2.34-2.15-2.34-4.33,0-8.04,9.23-11.45,18.44H7.57l2.4-6.06h-2.55l-2.37,6.06H.39l-.39,.96H4.67l-2.59,6.62c-.95,2.42-1.54,3.67-1.54,4.82,0,1.23,.74,2.12,2.45,2.12,3.18,0,5.92-3.51,7.13-4.98h.05l-1.85,4.64h2.41s.23-.38,.37-.62c2.9-4.84,5.81-9,7.73-9,.44,0,.66,.31,.66,.68,0,.61-.4,1.35-.84,2.25-.73,1.49-1.71,3.36-1.71,4.78,0,1.5,1.07,2.26,2.75,2.26,2.39,0,4.2-1.7,4.83-2.24,.16,.71,.85,2.24,3.37,2.24s4.35-1.75,4.92-2.39l-.44-.44c-.52,.49-1.86,1.86-3.7,1.86-1.56,0-2.04-1.15-1.97-2.28,3.96-1.07,7.39-3,7.39-5.93,0-.95-.69-2.07-2.55-2.07-2.96,0-7.03,4.08-7.14,8.18-.56,.5-2.06,1.88-3.81,1.88-.89,0-1.25-.52-1.25-1.12,0-1.01,1-2.82,1.76-4.55,.46-1.03,.82-1.8,.82-2.66,0-.96-.87-1.75-2.26-1.75-2.59,0-4.93,3.02-6.66,5.57h-.05Z" />
          <path d="M5.19,61.96s-.11,0,1.34,0c4.28,0,5.09-2.64,5.09-4.29s-.77-4.37-5.09-4.37h-1.34v8.65m0-11.49h1.45c2.57,0,4.18-1.67,4.18-4.14,0-2.24-1.36-4.1-4.18-4.1h-1.45v8.24Zm6.26,1.15c.67,.23,4,1.66,4,6.39,0,3.84-3.01,7.02-8.61,7.02H1.49v-25.79H7.32c5.34,0,7.26,3.42,7.26,6.79,0,3.68-2.29,5.12-3.13,5.49v.1Z" />
          <polyline points="20.35 39.23 30.01 39.23 30.01 42.24 24.06 42.24 24.06 50.28 29.8 50.28 29.8 53.3 24.06 53.3 24.06 62.02 30.18 62.02 30.18 65.03 20.35 65.03 20.35 39.23" />
          <polyline points="52.25 39.23 62.02 39.23 62.02 42.24 55.96 42.24 55.96 50.28 61.8 50.28 61.8 53.3 55.96 53.3 55.96 62.02 62.18 62.02 62.18 65.03 52.25 65.03 52.25 39.23" />
          <polyline points="41.06 58.66 44.82 39.23 48.45 39.23 42.97 65.03 38.72 65.03 33.32 39.23 37.16 39.23 40.91 58.66 41.06 58.66" />
          <polyline points="153.63 39.23 157.34 39.23 157.34 62.02 163.93 62.02 163.93 65.03 153.63 65.03 153.63 39.23" />
          <polyline points="85.83 39.23 89.55 39.23 89.55 62.02 96.11 62.02 96.11 65.03 85.83 65.03 85.83 39.23" />
          <polyline points="102.54 49.16 106.7 39.23 110.33 39.23 104.15 53.47 104.15 65.03 100.43 65.03 100.43 53.47 94.7 39.23 98.62 39.23 102.47 49.16 102.54 49.16" />
          <polyline points="122.61 39.23 126.33 39.23 126.33 50.33 132.17 50.33 132.17 39.23 135.87 39.23 135.87 65.03 132.17 65.03 132.17 53.34 126.33 53.34 126.33 65.03 122.61 65.03 122.61 39.23" />
          <polyline points="143.11 39.23 146.83 39.23 146.83 65.03 143.11 65.03 143.11 39.23" />
          <polyline points="162.97 39.23 176.85 39.23 176.85 42.12 171.76 42.12 171.76 65.03 168.05 65.03 168.05 42.12 162.97 42.12 162.97 39.23" />
          <path d="M190.06,52.11c0-6.64-1.18-10.24-4.07-10.24s-4.07,3.6-4.07,10.24,1.18,10.23,4.07,10.23,4.07-3.59,4.07-10.23m3.94,0c0,7.35-2.58,13.21-8.01,13.21s-8.02-5.86-8.02-13.21,2.53-13.22,8.02-13.22,8.01,5.87,8.01,13.22Z" />
          <path d="M71.25,52.33c.94,0,.26,0,.55,0,3.27,0,5.02-1.96,5.02-5.08,0-2.84-1.66-5.05-5.24-5.05h-.34v10.13m-3.72-13.09h4.67c5.59,0,8.35,3.55,8.35,7.92,0,5.81-4.14,7.36-4.64,7.58l5.72,10.29h-4.35l-5.13-9.69h-.9v9.69h-3.72v-25.79Z" />
          <polyline points="208.66 55.93 208.66 39.23 212.17 39.23 212.17 65.03 208.27 65.03 203.97 51.03 202.77 46.48 202.7 46.48 202.7 65.03 199.17 65.03 199.17 39.23 203.74 39.23 207.32 51.28 208.54 55.93 208.66 55.93" />
          <path d="M215.66,39.09c.98,0,1.82,.76,1.82,1.8s-.84,1.81-1.82,1.81-1.83-.76-1.83-1.81,.84-1.8,1.83-1.8m0,.29c-.84,0-1.48,.65-1.48,1.51s.65,1.52,1.48,1.52,1.47-.64,1.47-1.52-.65-1.51-1.47-1.51Zm-.38,2.55h-.32v-2.08h.8c.5,0,.74,.18,.74,.59,0,.38-.23,.53-.55,.57l.59,.91h-.35l-.56-.9h-.36v.9Zm0-1.17h.38c.39,0,.51-.11,.51-.34,0-.2-.1-.31-.46-.31h-.43v.65Z" />
        </g>
      </g>
    </svg>
  );
}
